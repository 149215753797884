<template>
  <div>
    <div class="card card-header-actions mb-4">
      <div class="card-header" v-if="!isForDashboard">
        <div v-if="$can('listar bitacoras de usuarios')">
          <b-form-checkbox
            v-model="checked"
            @change="changeHistoryType"
            switch
            inline
            checked
          >
            {{ title }}
          </b-form-checkbox>
        </div>
        <div v-else>{{ title }}</div>
      </div>
      <div class="card-header" v-else>
        Actividad reciente
      </div>
      <div class="card-body">
        <div class="timeline timeline-xs" v-if="getAvailableLogs">
          <div
            class="timeline-item"
            v-for="log in getActivityLogs"
            :key="log.id"
          >
            <div class="timeline-item-marker">
              <div class="timeline-item-marker-text">{{ log.fecha }}</div>
              <div :class="getIndicatorColor(log.color)"></div>
            </div>
            <div class="timeline-item-content">
              <strong>{{ getEventData(log.descripcion, 0) }}</strong>
              <span v-if="getEventData(log.descripcion, 1)">:{{ getEventData(log.descripcion, 1) }}</span>
              | <strong>Ejecutado por :</strong> {{ log.ejecutado_por }}
              <!-- Informacion a mostrar si existen cambios -->
              <div class="dropdown" v-if="!Array.isArray(log.cambios)">
                <button
                  class="btn btn-xs btn-primary dropdown-toggle"
                  id="dropdownMenuButton"
                  type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Cambios
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <pre class="dropdown-item"
                    v-for="(propiedad, key) in log.cambios"
                    :key="key">
                    <strong class="text-capitalize">{{ key }} anterior </strong>: {{ propiedad.anterior }} -&nbsp; <strong class="text-capitalize">{{ key }} actual </strong>: {{ propiedad.nuevo }}
                  </pre>
                </div>
              </div>
              <!-- Informacion a mostrar si es un registro creado -->
              <div class="dropdown" v-if="!Array.isArray(log.nuevo)">
                <button
                  class="btn btn-xs btn-primary dropdown-toggle"
                  id="dropdownMenuButton"
                  type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Datos
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <pre class="dropdown-item"
                    v-for="(propiedad, key) in log.nuevo"
                    :key="key">
                    <strong class="text-uppercase">{{ key }} </strong>: {{ propiedad }}
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
        <loading v-else></loading>
      </div>
      <div class="card-footer" v-if="!isForDashboard">
      <paginator
          aria-controls="historyTable"
          :disabled="!getAvailableLogs"
          :show-path="showPath"
          :resource="$store.state.historyModule.activityLogs.data">
        </paginator>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'History',

  async created () {
    await this.changeHistoryType(this.canListAll)
  },

  props: {
    isForDashboard: {
      type: Boolean,
      value: false
    }
  },

  data () {
    return {
      canListAll: this.$can('listar bitacoras de usuarios'),
      checked: true,
      fields: [
        { key: 'accion', label: 'Acción' },
        { key: 'accion_ejecutada_por', label: 'Ejecutada por' },
        { key: 'fecha', fecha: 'Fecha' },
        { key: 'user_agent', fecha: 'user_agent' }
      ]
    }
  },

  computed: {
    ...mapGetters('historyModule', [
      'getActivityLogs', 'getAvailableLogs', 'allItems'
    ]),

    showPath () {
      return this.checked && this.canListAll ? 'historyModule/getActivityLogs' : 'historyModule/getActivityLogsFromUser'
    },

    title () {
      return this.checked && this.canListAll ? 'Historial general' : 'Historial del usuario'
    }
  },

  methods: {
    async changeHistoryType () {
      await this.$store.dispatch(`${this.showPath}`)
    },

    getIndicatorColor (color) {
      return `timeline-item-marker-indicator bg-${color}`
    },

    getEventData (description, position) {
      const descriptionArray = description.split('|')
      return descriptionArray[position]
    }
  }
}
</script>

<style lang="scss" scoped>
  .timeline-item-marker-text {
    width: 6rem !important;
  }

  pre {
    margin-bottom: 0;
    font-family: inherit;
    font-size: inherit;
  }
</style>
